<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Trocar Senha</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <form [formGroup]="contactForm">
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="legacy" fxFlex>
                    <mat-label>Senha</mat-label>
                    <input name="password" formControlName="password" matInput type="password">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="legacy" fxFlex>
                    <mat-label>Confirmar senha</mat-label>
                    <input name="passwordConfirmation" formControlName="passwordConfirmation" matInput type="password">
                </mat-form-field>
            </div>
            <div *ngIf="contactForm.controls.password.hasError('strong')" class="message-box warning">
                <small>Informe uma senha complexa (é preciso conter letras maiúsculas, minúsculas e números)</small>
            </div>
            <div *ngIf="contactForm.hasError('notMatch')" class="message-box error mt-4">
                <label class='warning'><small>As senhas precisam ser iguais!</small></label>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="m-0 p-12 mat-dialog-actions" fxLayout="row" fxLayoutAlign="start center">
        <div>
            <button mat-raised-button
                    color="accent"
                    (click)="save()"
                    class="save-button"
                    [disabled]="contactForm.invalid"
                    aria-label="SAVE" class="mat-accent add-todo-button mat-raised-button mat-button-base mr-4">
                SALVAR
            </button>
            <button mat-raised-button (click)="matDialogRef.close(false)">CANCELAR</button>
        </div>
    </div>        

</div>
