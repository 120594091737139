import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


import { TranslateService } from '@ngx-translate/core';

import { User } from 'app/model/User';
import { AllServices } from 'app/services/all.service';
import { MatSnackBar } from '@angular/material/snack-bar';

var sha1 = require('sha1');

@Component({
    selector     : 'change-password-form-dialog',
    templateUrl  : './change-password-form.component.html',
    styleUrls    : ['./change-password-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ChangePasswordFormDialogComponent
{
    action: string;
    contact: User;
    contactForm: FormGroup;
    dialogTitle: string;
    fromUser: false;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ChangePasswordFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private allServices: AllServices,
        private translate: TranslateService,
        private _snackBar: MatSnackBar,
    )
    {
        // Set the defaults
        this.action = _data.action;

        if ( this.action === 'edit' )
        {
            this.dialogTitle = 'Edit Contact';
            this.contact = _data.contact;
            this.fromUser = _data.fromUser;
        }

        this.contactForm = this.createContactForm();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createContactForm(): FormGroup
    {
        return this._formBuilder.group({
            password: [ '',
                [
                  Validators.required,
                  PasswordValidator.strong
                 ]
            ],
            passwordConfirmation  : ['',
            [
              Validators.required,
              PasswordValidator.strong
             ]],
        },  {validators: checkPasswords});
    }
    
    save() {

        if (this.fromUser) {
            this.contact['password'] = sha1(this.contactForm.controls.password.value);
            this.allServices.putUser(this.contact, this.contact.id).subscribe(data => {
                if (data.status == 200) {
                    this._snackBar.open('Senha alterada com sucesso');
                }
            }, error => {
                this._snackBar.open('Erro na troca de senha');
            });
    
        } else {
            this.allServices.setPassword(this.contactForm.controls.password.value).subscribe(data => {
                if (data.status == 200) {
                    this._snackBar.open('Senha alterada com sucesso');
                }
            }, error => {
                this._snackBar.open('Erro na troca de senha');
            });

        }
        this.matDialogRef.close(['send']);
    }
}

export const checkPasswords: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const pass1 = control.get('password');
    const pass2 = control.get('passwordConfirmation');
    
    return pass1 && pass2 && pass1.value === pass2.value ? null : {'notMatch': true};
};

export class PasswordValidator {

    public static strong(control: FormControl): ValidationErrors | null {
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        const valid = hasNumber && hasUpper && hasLower;
        if (!valid) {
            // return what´s not valid
            return { strong: true };
        }
        return null;
    }
}

