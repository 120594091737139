import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../model/User';
import * as jwt_decode from "jwt-decode";

var sha1 = require('sha1');

@Injectable({
    providedIn: 'root',
})
export class AllServices {

    private _onUserChanged: BehaviorSubject<any>;
    private _onLanguageChanged: BehaviorSubject<any>;

    API_URL = environment.api_url;

    user: User;
    appointment_id;
    dateFormat = 'DD/MM/YYYY';
    pattern = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
    currencySymbol = 'R$';
    decimalPoint = ',';
    tokenData: any;

    public selectedProfessional: User;

    public Currency = { name: 'Real Brasileiro', currency: 'BRL', price: 1.0 } // Default Currency

    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }),
    };

    headersGuest = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJMaXN0ZW5PbmxpbmUgU2VydmVyIiwic3ViIjoiMCIsInRpbWVzdGFtcCI6IjIwMjAtMDktMjNUMDg6MTQ6MzktMDM6MDAiLCJ0eXBlIjoiU1lTIiwibmFtZSI6IlN5c3RlbSIsImVtYWlsIjoic3lzdGVtIn0.fJ_-eBOa-_XPTSaeFnryiLhDTDGeewJZVwKYHFCa40o',
        }),
    };

    constructor(
        private http: HttpClient,
        private _translateService: TranslateService,
    ) {
        this._onUserChanged = new BehaviorSubject(null);
        this._onLanguageChanged = new BehaviorSubject(null);
        this.user = new User(null);
    }

    postDiagnosticsSendLog(log_text: any): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'diagnostics/send_log',
            {
                'log_text': log_text,
                'sender_name': this.user.name
            },
            headers);
    }

    authenticate(email: any, password: any): Observable<any> {
        //let passmd5 = Md5.hashStr(password);
        let passmd5 = sha1(password);
        return this.http.post<any>(this.API_URL + 'auth',
            {
                'email': email,
                'password': passmd5,
            },
            this.headers);
    }

    authenticateByToken(token: any): Observable<any> {
        return this.http.post<any>(this.API_URL + 'auth/token',
            {
                'token': token,
            },
            this.headers);
    }

    authenticateByAppointmentID(id: any, type: any): Observable<any> {
        return this.http.post<any>(this.API_URL + 'auth/appointment/' + id + '/' + type,
            null,
            this.headers);
    }

    resetPassword(request: any): Observable<any> {
        return this.http.put<any>(this.API_URL + 'auth/reset_password/' + request, {}, this.headers);
    }

    setPassword(request: any): Observable<any> {
        //let passmd5 = Md5.hashStr(request);
        let passmd5 = sha1(request);
        let headers = this.getToken();

        return this.http.put<any>(this.API_URL + 'users/set_password', { password: passmd5 }, headers);
    }

    renewUserData() {
        this.getUser().subscribe(data => {
            this.setUser(data.user);
        });
    }

    getUser(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'users/my_data', headers);
    }

    getMyHome(): Observable<any> {
        let headers = this.getToken();

        return this.http.get<any>(this.API_URL + 'employees/my_home', headers);
    }

    getToken(): any {
        let token = localStorage.getItem('token');
        this.tokenData = jwt_decode(token);

        let headers = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
        };

        return headers;
    }

    setUser(user) {
        this.user = user;
        if (user.image_url == null) {
            this.user.image_url = '/assets/images/avatars/profile.jpg';
        } else {
            if (user.image_url.length == 0) {
                this.user.image_url = '/assets/images/avatars/profile.jpg';
            }
        }
        if (user.profile_img_url == null) {
            this.user.profile_img_url = '/assets/images/avatars/profile.jpg';
        } else {
            if (user.profile_img_url.length == 0) {
                this.user.profile_img_url = '/assets/images/avatars/profile.jpg';
            }
        }
        this._onUserChanged.next({ user: user });
    }

    getAddress(zipcode): Observable<any> {
        return this.http.get<any>('https://viacep.com.br/ws/' + zipcode + '/json/');
    }

    getBaseData(module) {
        if (module == 'professionals') {
            return this.getProfessionals();
        } else if (module == 'specialties') {
            return this.getSpecialties();
        } else if (module == 'regions') {
            return this.getRegions();
        } else if (module == 'audiences') {
            return this.getAudiences();
        } else if (module == 'specialization_areas') {
            return this.getSpecializationAreas();
        } else if (module == 'banks') {
            return this.getBanks();
        } else {
            return this.getEducations();
        }
    }

    getProfessionals(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'professional_types/', headers);
    }

    getSpecialties(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'specialties/', headers);
    }

    getRegions(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'crp_regions/', headers);
    }

    getAudiences(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'audiences/', headers);
    }

    getEducations(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'education_types/', headers);
    }

    getSpecializationAreas(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'specialization_areas/', headers);
    }

    getSpecializationAreasByType(type_id): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'specialization_areas/null/' + type_id, headers);
    }

    getUsers(module): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'users/null/' + module, headers);
    }

    getUserByID(id): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'users/' + id, headers);
    }

    getBanks(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'banks', headers);
    }

    getBankByID(id): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'banks/' + id, headers);
    }

    getAppointments(appointment_id, user_id_professional, user_id_customer, start_date, end_date): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'appointments/' + appointment_id + '/' + user_id_professional + '/' + user_id_customer + '/' + start_date + '/' + end_date, headers);
    }

    getAppointmentByID(appointment_id): Observable<any> {
        let headers = this.getToken();
        //return this.http.get<any>(this.API_URL + 'appointments/' + appointment_id + '/null/null/null/null/null', headers);
        return this.http.get<any>(this.API_URL + 'appointments/' + appointment_id, headers);
    }

    getGuestAppointmentByID(appointment_id): Observable<any> {
        return this.http.get<any>(this.API_URL + 'appointments/guest/' + appointment_id, this.headersGuest);
    }

    getAllAppointments(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'appointments/null/null/null/null/null', headers);
    }

    getInstallments(value, appointment_id): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'checkout/installments?total=' + value + '&appointment_id=' + appointment_id, headers);
    }

    getUserOnline(user_id, appointment_id): Observable<any> {
        //let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'users/online/' + user_id + '/' + appointment_id, this.headersGuest);
    }

    getCoupons(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'coupons/' + this.user.id, headers);
    }

    getCouponByCode(professional_id, code): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'coupons/validate/' + professional_id + '/' + code, headers);
    }

    getEvents(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'events/null/' + this.user.id, headers);
    }

    getAllEvents(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'events', headers);
    }

    getEventTypes(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'event_types', headers);
    }

    deleteFromModule(module, id) {
        if (module == 'professionals') {
            return this.deleteProfessionals(id);
        } else if (module == 'specialties') {
            return this.deleteSpecialties(id);
        } else if (module == 'regions') {
            return this.deleteRegions(id);
        } else if (module == 'audiences') {
            return this.deleteAudiences(id);
        } else if (module == 'specialization_areas') {
            return this.deleteSpecializationAreas(id);
        } else if (module == 'banks') {
            return this.deleteBanks(id);
        } else {
            return this.deleteEducations(id);
        }
    }

    deleteProfessionals(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'professional_types/' + id, headers);
    }

    deleteSpecialties(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'specialties/' + id, headers);
    }

    deleteRegions(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'crp_regions/' + id, headers);
    }

    deleteAudiences(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'audiences/' + id, headers);
    }

    deleteEducations(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'education_types/' + id, headers);
    }

    deleteSpecializationAreas(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'specialization_areas/' + id, headers);
    }

    deleteBanks(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'banks/' + id, headers);
    }

    deleteUser(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'users/' + id, headers);
    }

    deleteAppointment(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'appointments/' + id, headers);
    }

    deleteCoupon(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'coupons/' + id, headers);
    }
    createBase(module, data) {
        if (data.id) {
            delete data['id'];
        }
        if (module == 'professionals') {
            return this.postProfessionals(data);
        } else if (module == 'specialties') {
            return this.postSpecialties(data);
        } else if (module == 'regions') {
            return this.postRegions(data);
        } else if (module == 'audiences') {
            return this.postAudiences(data);
        } else if (module == 'specialization_areas') {
            return this.postSpecializationAreas(data);
        } else if (module == 'banks') {
            return this.postBanks(data);
        } else {
            return this.postEducations(data);
        }
    }

    postProfessionals(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'professional_types/', data, headers);
    }

    postSpecialties(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'specialties/', data, headers);
    }

    postRegions(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'crp_regions/', data, headers);
    }

    postAudiences(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'audiences/', data, headers);
    }

    postSpecializationAreas(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'specialization_areas/', data, headers);
    }

    postBanks(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'banks/', data, headers);
    }

    postEducations(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'education_types/', data, headers);
    }

    postProfessionalApproval(data, id, type): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'professionals/approval/' + type + '/' + id, data, headers);
    }

    postProfessionalRequestForApproval(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'professionals/approval/REQUEST/' + this.user.professional_id, data, headers);
    }

    postUser(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'users/', data, headers);
    }

    postAppointments(body): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'appointments/', body, headers);
    }

    postCheckout(appointment_id, body): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'checkout/' + appointment_id, body, headers);
    }

    postCoupon(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'coupons/' + this.user.id, data, headers);
    }

    updateUserImage(id, data): Observable<any> {
        let headers = this.getToken();
        let body = { profile_img_url: data }
        return this.http.put<any>(this.API_URL + 'users/set_image/' + id, body, headers);
    }

    updateBase(module, data, id) {
        if (module == 'professionals') {
            return this.putProfessionals(data, id);
        } else if (module == 'specialties') {
            return this.putSpecialties(data, id);
        } else if (module == 'regions') {
            return this.putRegions(data, id);
        } else if (module == 'audiences') {
            return this.putAudiences(data, id);
        } else if (module == 'specialization_areas') {
            return this.putSpecializationAreas(data, id);
        } else if (module == 'banks') {
            return this.putBanks(data, id);
        } else {
            return this.putEducations(data, id);
        }
    }

    putProfessionals(data, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'professional_types/' + id, data, headers);
    }

    putSpecialties(data, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'specialties/' + id, data, headers);
    }

    putRegions(data, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'crp_regions/' + id, data, headers);
    }

    putAudiences(data, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'audiences/' + id, data, headers);
    }

    putEducations(data, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'education_types/' + id, data, headers);
    }

    putSpecializationAreas(data, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'specialization_areas/' + id, data, headers);
    }

    putBanks(data, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'banks/' + id, data, headers);
    }

    putAcceptTerm(): Observable<any> {
        let headers = this.getToken();

        if (this.user.type == 'PRO') {
            return this.http.put<any>(this.API_URL + 'professionals/accept_term/' + this.user.professional_id, null, headers);
        }
        if (this.user.type == 'CUS') {
            return this.http.put<any>(this.API_URL + 'customers/accept_term/' + this.user.customer_id, null, headers);
        }
    }

    putAcceptImageTerm(): Observable<any> {
        let headers = this.getToken();

        if (this.user.type == 'PRO') {
            return this.http.put<any>(this.API_URL + 'professionals/accept_image_term/' + this.user.professional_id, null, headers);
        }
    }

    putUser(data, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'users/' + id, data, headers);
    }

    putOnline(user_id, appointment_id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'users/online/' + user_id + '/' + appointment_id, null, headers);
    }

    putAppointmentEvaluate(body, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'appointments/evaluate/' + id, body, headers);
    }

    putAppointments(body, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'appointments/' + id, body, headers);
    }

    putCoupons(body, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'coupons/' + id, body, headers);
    }

    cancelAppointment(id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'appointments/cancel/' + id + '/' + this.user.id, null, headers);
    }

    rescheduleAppointment(id, body): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'appointments/reschedule/' + id, body, headers);
    }

    updateUserData(): Observable<any> {
        return this.putUser(this.user, this.tokenData.sub);
    }

    createProfessionalSpecialty(data_id): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'professionals/specialties/' + this.user.professional_id, { specialty_id: data_id }, headers);
    }

    deleteProfessionalSpecialty(data_id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'professionals/specialties/' + this.user.professional_id + '/' + data_id, headers);
    }

    createProfessionalEducation(data): Observable<any> {
        let headers = this.getToken();
        data.specialization_areas = [];
        for (let d of data.specializations) {
            data.specialization_areas.push({ id: d })
        }
        return this.http.post<any>(this.API_URL + 'professionals/educations/' + this.user.professional_id, data, headers);
    }

    updateProfessionalEducation(data): Observable<any> {
        let headers = this.getToken();
        data.specialization_areas = [];
        for (let d of data.specializations) {
            data.specialization_areas.push({ id: d })
        }
        return this.http.put<any>(this.API_URL + 'professionals/educations/' + this.user.professional_id + '/' + data.id, data, headers);
    }

    deleteProfessionalEducation(data_id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'professionals/educations/' + this.user.professional_id + '/' + data_id, headers);
    }

    createProfessionalAudience(data_id): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'professionals/audiences/' + this.user.professional_id, { audience_id: data_id }, headers);
    }

    deleteProfessionalAudience(data_id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'professionals/audiences/' + this.user.professional_id + '/' + data_id, headers);
    }

    getDiscountPackages(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'discount_packages/', headers);
    }

    getDiscountPackagesbyType(id): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'discount_packages/null/' + id, headers);
    }
    postDiscountPackages(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'discount_packages/', data, headers);
    }

    putDiscountPackages(data): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'discount_packages/' + data.id, data, headers);
    }

    deleteDiscountPackages(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'discount_packages/' + id, headers);
    }

    postProfessionalDiscountPackage(professional_id, id): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'professionals/discount_packages/' + professional_id, { discount_package_id: id }, headers);
    }

    deleteProfessionalDiscountPackage(professional_id, id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'professionals/discount_packages/' + professional_id + '/' + id, headers);
    }


    postProfessionalDocument(data, name): Observable<any> {
        let headers = this.getToken();
        let body = { url: data, name: name }
        return this.http.post<any>(this.API_URL + 'professionals/documents/' + this.user.professional_id, body, headers);
    }

    deleteProfessionalDocument(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'professionals/documents/' + id, headers);
    }

    postProfessionalAvailabletimes(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'professionals/available_times/' + this.user.professional_id, data, headers);
    }

    // Eventos
    postEvent(data): Observable<any> {
        let headers = this.getToken();
        return this.http.post<any>(this.API_URL + 'events/', data, headers);
    }

    putEvent(data, id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'events/' + id, data, headers);
    }

    deleteEvent(id): Observable<any> {
        let headers = this.getToken();
        return this.http.delete<any>(this.API_URL + 'events/' + id, headers);
    }

    putEventCertifyUser(event_id, user_id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'events/certify_user/' + event_id + '/' + user_id, null, headers);
    }

    putEventUncertifyUser(event_id, user_id): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'events/uncertify_user/' + event_id + '/' + user_id, null, headers);
    }

    putEventSpeakerImage(event_id, data): Observable<any> {
        let headers = this.getToken();
        return this.http.put<any>(this.API_URL + 'events/set_speaker_image/' + event_id, data, headers);
    }

    generateCertificate(appointment, event): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'certificates/generate_certificate_pdf/' + appointment + '/' + event, headers);
    }

    getTimeZones(): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'timezones', headers);
    }

    get onUserChanged(): Observable<any> {
        return this._onUserChanged.asObservable();
    }

    get onLanguageChanged(): Observable<any> {
        return this._onLanguageChanged.asObservable();
    }

    //////////////////////////////////////////
    //////////////////////////////
    /////////////////
    /// Chamadas compartilhadas com o allServices do site
    //////////////////////////////////////////
    //////////////////////////////
    /////////////////

    getProfessionalAvailibilities(id, date): Observable<any> {
        let headers = this.getToken();
        return this.http.get<any>(this.API_URL + 'professionals/availabilities/' + id + '?startDate=' + date, headers);
    }

    searchUsers(query: string): Observable<any> {
        let headers = this.getToken();
        var searchquery = ''
        if (query) {
            searchquery = '?' + query
        }
        return this.http.get<any>(this.API_URL + 'users/search/PRO' + searchquery, headers);
    }

    getMethod(value) {
        if (value == 'CREDITCARD') return 'Cartão de Crédito';
        if (value == 'DEBITCARD') return 'Cartão de Débito';
        if (value == 'BANKSLIP') return 'Boleto';
        if (value == 'FREE') return 'Isento';
        return '';
    }

    getPaymentStatus(value) {
        if (value == 'CANCELLED') return 'Cancelado';
        if (value == 'FREE') return 'Pago/Isento';
        if (value == 'PAID') return 'Pago';
        if (value == 'PENDING') return 'Pendente';
        if (value == 'REFUNDED') return 'Reembolsado';
        if (value == 'UNFINISHED') return 'Não Concluído';
        return value;
    }
}