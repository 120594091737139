export class User {
    id: string;
    name: string;
    type: string;
    mobile: string;
    vat: string;
    email: string;
    birth_date: Date;
    creation: Date;
    creationFormatted: string;

    address_neighborhood: string;
    address_city_name: string;
    address_state: string;
    address_zipcode: string;
    address_street: string;
    address_number: string;
    address_complement: string;

    phone: string;
    gender: string;
    professional_id: string;
    customer_id: string;
    professional_type_id: string;
    professional_type_name: string;
    id_card_number: string;
    registration_number: string;
    crp_region_id: string;
    crp_region_name: string;
    language: string;
    currency: string;
    image_url: string;
    video_url: string;
    password: string;
    slug: string;

    free_anamnesis: boolean;
    show_appointment_value: boolean;
    appointment_value: number;

    term_acceptance: Date;
    image_term_acceptance: Date;
    status: string;
    display_status: string;
    review_observations: string;

    curriculum: string;
    facebook_profile_url: string;
    linkedin_profile_url: string;
    profile_img_url: string;
    instagram_profile_url: string;
    curriculum_lattes: string;

    bank_id: string;
    bank_agency: string;
    bank_agency_digit: string;
    bank_account: string;
    bank_account_digit: string;
    account_type: string;

    timezone: string;

    audiences: [];
    specialties: [];
    educations: [];
    discount_packages: [];
    documents: [];
    approval_history: [];
    available_times: [];

    days: [];
    screenAvailableTimes: [];

    constructor(contact) {
        if (contact) {
            this.id = contact.id || '0';
            this.name = contact.name || '';
            this.profile_img_url = contact.profile_img_url || "assets/images/avatars/profile.jpg";
            this.creation = contact.creation;
            this.creationFormatted = contact.creationFormatted || '';

            //Specialties
            this.type = contact.type || 'ADM';
            this.email = contact.email || '';
            this.professional_id = contact.professional_id;
            this.professional_type_name = contact.professional_type_name;
            this.professional_type_id = contact.professional_type_id;
            this.registration_number = contact.registration_number;
            this.crp_region_id = contact.crp_region_id;
            this.id_card_number = contact.id_card_number;
            this.vat = contact.vat;
            this.gender = contact.gender;
            this.phone = contact.phone;
            this.mobile = contact.mobile;
            this.term_acceptance = contact.term_acceptance || null;
            this.image_term_acceptance = contact.image_term_acceptance || null;
            this.status = contact.status || 'I';
            this.display_status = contact.display_status

            this.audiences = contact.audiences || [];
            this.specialties = contact.specialties || [];
            this.discount_packages = contact.discount_packages || [];
            this.documents = contact.documents || [];
            this.educations = contact.educations || [];
            this.approval_history = contact.approval_history || [];

            this.bank_agency = contact.bank_agency;
            this.bank_agency_digit = contact.bank_agency_digit
            this.bank_account = contact.bank_account;
            this.bank_account_digit = contact.bank_account_digit;
            this.bank_id = contact.bank_id;
            this.account_type = contact.account_type;

            this.timezone = contact.timezone;

            this.address_city_name = contact.address_city_name;
            this.address_complement = contact.address_complement;
            this.address_neighborhood = contact.address_neighborhood;
            this.address_number = contact.address_number;
            this.address_state = contact.address_state;
            this.address_street = contact.address_street;
            this.address_zipcode = contact.address_zipcode;

            this.available_times = contact.available_times || [];

            this.birth_date = contact.birth_date;

            this.appointment_value = contact.appointment_value;
            this.free_anamnesis = contact.free_anamnesis;
            this.show_appointment_value = contact.show_appointment_value;

            this.facebook_profile_url = contact.facebook_profile_url;
            this.linkedin_profile_url = contact.linkedin_profile_url;
            this.instagram_profile_url = contact.instagram_profile_url;
            this.video_url = contact.video_url;

            this.curriculum_lattes = contact.curriculum_lattes;
            this.curriculum = contact.curriculum;

            this.slug = contact.slug;
        }
    }
}