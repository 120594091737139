export const locale = {
    lang: 'pt',
    data: {
        'NAV': {
            'APPLICATIONS': 'Aplicações',
            'DASHBOARDS'  : 'Dashboards',
            'CALENDAR'    : 'Calendário',
            'ECOMMERCE'   : 'E-Commerce',
            'ACADEMY'     : 'Academy',
            'CHAT'        : 'Chat',
            'CONTACTS'    : 'Contatos',
            'TODO'        : 'To-Do',
            'SCRUMBOARD'  : 'Scrumboard'
        }
    }
};
