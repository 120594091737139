import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Agenda',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'calendar',
                title: 'Minha Agenda',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'calendar_today',
                url: '/apps/calendar'
            },
            {
                id: 'consultas',
                title: 'Minhas Consultas',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'today',
                url: '/pages/appointments/appointment'
            },
            {
                id: 'meuseventos',
                title: 'Minhas Inscrições',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'event_note',
                url: '/pages/appointments/event'
            }
        ]
    },
    {
        id: 'eventos',
        title: 'Eventos',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'eventos',
                title: 'Meus Eventos',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'cast_for_education',
                url: '/apps/events'
            },
            {
                id: 'events',
                title: 'Encontre um Evento',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'cast_for_education',
                url: '/pages/events'
            }
        ]
    },
    {
        id: 'outros',
        title: 'Outros',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'cupons',
                title: 'Cupons de Desconto',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'local_activity',
                url: '/apps/coupons'
            },
            {
                id: 'tutorial',
                title: 'Ajuda',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'help_center',
                url: '/pages/knowledge-base'
            },
            {
                id: 'diagnostico',
                title: 'Diagnóstico áudio/vídeo',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'featured_video',
                url: '/pages/video-test/PRO'
            },
        ]
    },
    {
        id: 'perfil',
        title: 'Perfil',
        type: 'group',
        icon: 'pages',
        children: [
            {
                id: 'profile',
                title: 'Meu Perfil',
                type: 'item',
                icon: 'person',
                url: '/pages/profile'
            },

        ]
    },
];

export const navigationCUS: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Agenda',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'calendar',
                title: 'Minha Agenda',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'calendar_today',
                url: '/apps/calendar'
            },
            {
                id: 'consultas',
                title: 'Minhas Consultas',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'today',
                url: '/pages/appointments/appointment'
            },
            {
                id: 'eventos',
                title: 'Minhas Inscrições',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'event_note',
                url: '/pages/appointments/event'
            },
        ]
    },
    {
        id: 'facilidades',
        title: 'Facilidades',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'search',
                title: 'Encontre um Profissional',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'search',
                url: '/pages/search'
            },
            {
                id: 'events',
                title: 'Encontre um Evento',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'cast_for_education',
                url: '/pages/events'
            },
            {
                id: 'tutorial',
                title: 'Ajuda',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'help_center',
                url: '/pages/knowledge-base'
            },
            {
                id: 'diagnostico',
                title: 'Diagnóstico áudio/vídeo',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'featured_video',
                url: '/pages/video-test/CUS'
            },
        ]
    },
    {
        id: 'perfil',
        title: 'Perfil',
        type: 'group',
        icon: 'pages',
        children: [
            {
                id: 'profile',
                title: 'Meu Perfil',
                type: 'item',
                icon: 'person',
                url: '/pages/profile'
            },

        ]
    },
];

export const navigationAdmin: FuseNavigation[] = [
    {
        id: 'base',
        title: 'Usuários',
        type: 'group',
        icon: 'pages',
        children: [
            {
                id: 'users',
                title: 'Usuários',
                type: 'item',
                icon: 'person',
                url: 'apps/user/ADM'
            },
            {
                id: 'professionals',
                title: 'Profissionais',
                type: 'item',
                icon: 'account_box',
                url: 'apps/user/PRO'
            },
            {
                id: 'customers',
                title: 'Clientes',
                type: 'item',
                icon: 'person_pin',
                url: 'apps/user/CUS'
            },

        ]
    },
    {
        id: 'base',
        title: 'Aplicações',
        type: 'group',
        icon: 'pages',
        children: [
            {
                id: 'calendar',
                title: 'Agenda',
                type: 'item',
                icon: 'today',
                url: '/apps/calendar'
            },

        ]
    },

    {
        id: 'base',
        title: 'Cadastros Básicos',
        type: 'group',
        icon: 'pages',
        children: [
            {
                id: 'specialization_areas',
                title: 'Áreas de Especialização',
                type: 'item',
                icon: 'folder',
                url: 'apps/base/specialization_areas'
            },
            {
                id: 'audiences',
                title: 'Audiências',
                type: 'item',
                icon: 'folder',
                url: 'apps/base/audiences'
            },
            {
                id: 'banks',
                title: 'Bancos',
                type: 'item',
                icon: 'folder',
                url: 'apps/base/banks'
            },
            {
                id: 'specialties',
                title: 'Especialidades',
                type: 'item',
                icon: 'folder',
                url: 'apps/base/specialties'
            },
            {
                id: 'discountpackages',
                title: 'Pacotes de Descontos',
                type: 'item',
                icon: 'folder',
                url: 'apps/discount'
            },
            {
                id: 'regions',
                title: 'Regiões de CRP',
                type: 'item',
                icon: 'folder',
                url: 'apps/base/regions'
            },
            {
                id: 'educations',
                title: 'Tipos de Formação',
                type: 'item',
                icon: 'folder',
                url: 'apps/base/educations'
            },
            {
                id: 'professionals',
                title: 'Tipos de Profissionais',
                type: 'item',
                icon: 'folder',
                url: 'apps/base/professionals'
            }
        ]
    },
    {
        id: 'support',
        title: 'Suporte',
        type: 'group',
        icon: 'live_help',
        children: [
            {
                id: 'diagnostico',
                title: 'Suporte ao profissional',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'featured_video',
                url: '/pages/video-test/PRO'
            },
            {
                id: 'diagnostico',
                title: 'Suporte ao paciente',
                translate: 'NAV.CALENDAR',
                type: 'item',
                icon: 'featured_video',
                url: '/pages/video-test/CUS'
            }
        ]
    },


];