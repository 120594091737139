import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {CookieService} from 'ngx-cookie-service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseMatchMediaService} from '@fuse/services/match-media.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {AllServices} from 'app/services/all.service';

@Component({
    selector: 'fuse-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls: ['./shortcuts.component.scss']
})
export class FuseShortcutsComponent implements OnInit, AfterViewInit, OnDestroy {
    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;

    @Input()
    navigation: any;

    @ViewChild('searchInput')
    searchInputField;

    @ViewChild('shortcuts')
    shortcutsEl: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {MediaObserver} _mediaObserver
     * @param {Renderer2} _renderer
     */
    constructor(
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _fuseNavigationService: FuseNavigationService,
        private _mediaObserver: MediaObserver,
        private _renderer: Renderer2,
        private allServices: AllServices,
    ) {
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the navigation items and flatten them
        this.filteredNavigationItems = this.navigationItems = this._fuseNavigationService.getFlatNavigation(this.navigation);

        if (this._cookieService.check('FUSE2.shortcuts')) {
            this.shortcutItems = JSON.parse(this._cookieService.get('FUSE2.shortcuts'));
        }
        else {

        }

        this.allServices.onUserChanged.subscribe(data => {
            if (this.allServices.user.type == 'ADM') {
                // User's shortcut items
                this.shortcutItems = [
                    {
                        title: 'Página Inicial',
                        type: 'item',
                        icon: 'home',
                        url: '/apps/dashboards/analytics'
                    },
                    {
                        title: 'Calendário',
                        type: 'item',
                        icon: 'calendar_today',
                        url: '/apps/calendar'
                    },
                    {
                        title: 'Usuários',
                        type: 'item',
                        icon: 'person',
                        url: '/apps/user/ADM'
                    },
                    {
                        title: 'Profissionais',
                        type: 'item',
                        icon: 'account_box',
                        url: '/apps/user/PRO'
                    },
                    {
                        title: 'Clientes',
                        type: 'item',
                        icon: 'person_pin',
                        url: '/apps/user/CUS'
                    }
                ];
            } else {
                if (this.allServices.user.type == 'PRO') {
                    this.shortcutItems = [
                        {
                            title: 'Minha Agenda',
                            type: 'item',
                            icon: 'calendar_today',
                            url: '/apps/calendar'
                        },
                        {
                            title: 'Minhas Consultas',
                            type: 'item',
                            icon: 'today',
                            url: '/pages/appointments/appointment'
                        },
                        {
                            title: 'Minhas Inscrições',
                            type: 'item',
                            icon: 'event_note',
                            url: '/pages/appointments/event'
                        },
                        {
                            title: 'Perfil',
                            type: 'item',
                            icon: 'person',
                            url: '/pages/profile'
                        }
                    ];
                }
                if (this.allServices.user.type == 'CUS') {
                    this.shortcutItems = [
                        {
                            title: 'Minha Agenda',
                            type: 'item',
                            icon: 'calendar_today',
                            url: '/apps/calendar'
                        },
                        {
                            title: 'Minhas Consultas',
                            type: 'item',
                            icon: 'today',
                            url: '/pages/appointments/appointment'
                        },
                        {
                            title: 'Meus Eventos',
                            type: 'item',
                            icon: 'event_note',
                            url: '/pages/appointments/event'
                        },
                        {
                            title: 'Encontre um Profissional',
                            type: 'item',
                            icon: 'search',
                            url: '/pages/search'
                        },
                        {
                            title: 'Perfil',
                            type: 'item',
                            icon: 'person',
                            url: '/pages/profile'
                        }
                    ];
                }
            }
        })
    }

    ngAfterViewInit(): void {
        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if (this._mediaObserver.isActive('gt-sm')) {
                    this.hideMobileShortcutsPanel();
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Search
     *
     * @param event
     */
    search(event): void {
        const value = event.target.value.toLowerCase();

        if (value === '') {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }

        this.searching = true;

        this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
            return navigationItem.title.toLowerCase().includes(value);
        });
    }

    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle): void {
        event.stopPropagation();

        for (let i = 0; i < this.shortcutItems.length; i++) {
            if (this.shortcutItems[i].url === itemToToggle.url) {
                this.shortcutItems.splice(i, 1);

                // Save to the cookies
                this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));

                return;
            }
        }

        this.shortcutItems.push(itemToToggle);

        // Save to the cookies
        this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
    }

    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem): any {
        return this.shortcutItems.find(item => {
            return item.url === navigationItem.url;
        });
    }

    /**
     * On menu open
     */
    onMenuOpen(): void {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }
}
